<template>
  <div>
    <MyHeader />
    <main id="content">
      <ProfileMenu />
      <div class="container mt-4 mt-lg-5">
        <UserTripList :trips="inHouse"
                      title="In House" />
        <UserTripList :trips="upcomingTrips"
                      title="Upcoming Trips" />
        <UserTripList :trips="pendingTrips"
                      title="Pending Trips" />
        <UserTripList :trips="pastTrips"
                      title="Past Trips" />
        <UserTripList :trips="cancelledTrips"
                      title="Cancelled Trips" />
      </div>
      <ContactOurExperts />
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import ContactOurExperts from '../../components/users/ContactOurExperts.vue';
import UserTripList from '../../components/users/UserTripList.vue';

export default {
  name: 'UserMessageDetail',
  components: {
    ProfileMenu,
    ContactOurExperts,
    UserTripList
  },
  data() {
    return {
      upcomingTrips: [],
      pastTrips: [],
      cancelledTrips: [],
      pendingTrips: [],
      inHouse: []
    };
  },
  created() {
    this.$axios.get('/v1/bookings?status=1').then((status1) => {
      this.upcomingTrips = status1.data.data;
      this.$axios.get('/v1/bookings?status=2').then((status2) => {
        this.pendingTrips = status2.data.data;
        this.$axios.get('/v1/bookings?status=3').then((status3) => {
          this.cancelledTrips = status3.data.data;
          this.$axios.get('/v1/bookings?status=4').then((status4) => {
            this.pastTrips = status4.data.data;
            this.$axios.get('/v1/bookings?status=6').then((status6) => {
              this.inHouse = status6.data.data;
            });
          });
        });
      });
    });
  },
};
</script>

<style lang="scss" scoped>

</style>
