<template>
  <div>
    <div
      class="mostDemand mt-5"
      style="background-image: url('/img/most-img.jpg')">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-9 text-center">
            <h4><strong>Choose The Perfect Accommodations</strong></h4>
            <p>
              Your vacation time is valuable, which is why we handpick and
              inspect every property. The result? Vacation rentals that won’t
              leave you with unwanted surprises.
            </p>
            <router-link to="/contact"
                         class="btn btn-primary">
              <strong>Contact Our Experts</strong>
            </router-link>
            <div class="w-100 m-3" />
            <a class="link"
               href="#!">Our Call to: 1888-222-222</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
