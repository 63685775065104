<template>
  <div class="row">
    <div class="col-12">
      <h1 class="title text-primary">
        {{ title }}
      </h1>
    </div>
    <div v-if="trips && trips.length > 0"
         class="col-12">
      <div class="trip-box"
           v-for="(trip, index) in trips"
           :key="index">
        <router-link class="thumb"
                     style="width: 300px;"
                     :to="{ name: 'user-trip-overview', params: { id: trip.id } }">
          <span v-if="trip.property.hightlight">{{ trip.property.hightlight }}</span>
          <img v-if="trip.property && trip.property.images.length > 0"
               :src="trip.property.images[0].medium">
        </router-link>
        <div class="info">
          <div class="head">
            <div>
              <h4>
                <router-link
                  :to="{ name: 'property', params: { id: trip.property.id } }">
                  {{ trip.property.name }}
                </router-link>
              </h4>
              <p>{{ trip.property.state.name }}, {{ trip.property.country.name }}</p>
            </div>
            <div>
              <p class="confnum">
                Confirmation number: <strong>{{ trip.confirmation_number }}</strong>
              </p>
            </div>
          </div>
          <div class="d-flex flex-wrap border-top border-bottom mb-3">
            <label class="calenBook border-right pr-4 mr-3">
              <i class="icon-calendar" />
              <div><small>Check In</small> {{ trip.check_in|beatyDate }}</div>
              <i class="icon-arrow-right" />
              <div><small>Check Out</small> {{ trip.check_out|beatyDate }}</div>
            </label>
            <label class="calenBook">
              <i class="far fa-user" />
              <div><small>Travelers</small> {{ trip.travelers }}</div>
            </label>
          </div>
          <nav>
            <router-link class="btn col-12 col-sm-auto btn-outline-primary btn-sm mt-2 mt-sm-0 ml-lg-2"
                         :to="{ name: 'user-trip-overview', params: { id: trip.id } }">
              VIEW TRIP DETAILS
            </router-link>
            <router-link class="btn col-12 col-sm-auto mt-2 mt-sm-0 btn-outline-primary btn-sm ml-lg-2"
                         :to="{ name: 'user-message', params: { id: trip.id }}">
              CONTACT CONCIERGE
            </router-link>
            <router-link
              v-if="trip.status === 1"
              class="btn col-12 col-sm-auto mt-2 mt-sm-0 btn-outline-primary btn-sm ml-lg-2"
              :to="{ name: 'edit-or-cancel-trip', params: { id: trip.id } }">
              MODIFY OR CANCEL TRIP
            </router-link>
            <router-link
              v-if="trip.status === 2 || trip.status === 5"
              class="btn col-12 col-sm-auto mt-2 mt-sm-0 btn-outline-primary btn-sm ml-lg-2"
              :to="{ name: 'user-trip-cancellation', params: { id: trip.id } }">
              CANCEL TRIP
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <div v-if="trips.length <= 0 && isReady"
         class="col-12">
      <div class="alert alert-gray"
           role="alert">
        Sorry! You don’t have any {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    trips: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      isReady: false
    };
  },
  created() {
    setTimeout(() => {
      this.isReady = true;
    }, 1000);
  },
};
</script>
<style>
i {
  color: #11568C;
}
</style>
